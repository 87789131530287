<template>
  <div id="app">
    <router-link to="/"></router-link>
    <router-view />
  </div>
</template>

<script>
import './assets/css/public.css'  //引入公用css
export default {
  name: 'App',
  async beforeCreate() {
    // 初始化web3
    if (!this.$store.state.dapp.web3.web3Instance) {
       await this.$store.dispatch('dapp/registerWeb3')
    }
  },
  data(){
    return {
      timer: null
    }
  },
  methods:{
    getData(){
      this.$store.dispatch('dapp/getData')
    }
  },
  created() {
    this.timer = setInterval(this.getData, 16000);
    this.getData();
  },
  destroyed() {
    if(this.timer){
      clearTimeout(this.timer);
    }
  
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f7fb;
  position: relative;
}
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
nav {
  padding: 30px;
}
p{
  margin: 0;
  padding: 0;
}
</style>
