import Web3 from 'web3'
import { chainNetwork } from '@/contants'
import store from '@/store'
const getWeb3 = () => new Promise(async function(resolve, reject) {
  let ethereum;
  let type;
  if (window.ethereum) {
    ethereum = window.ethereum;
    type = 2;
  }else if (typeof window.okxwallet !== 'undefined') {
    ethereum = window.okxwallet;
    type = 1;
  }else{
    return;
  }
  // 切换账号
  ethereum.on('accountsChanged', function(accounts) {
      store.dispatch('dapp/accountsChanged', {account: accounts[0]});

    })
  // 网络修改
  ethereum.on('chainChanged', function(networkId) {
      // 刷新
      location.reload()
    })

  // 断开连接
  ethereum.on('disconnect', function(error) {
    })

  // 连接
  ethereum.on('connect', function(connectInfo) {
      console.dir(`connect = ${JSON.stringify(connectInfo)}`)
    })

  // 获取账号
  let account = null
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' })
  const accountsCatch = localStorage.getItem('accountsCatch')
  if (accountsCatch && accountsCatch !== accounts[0]) {
    localStorage.clear()
  }
  localStorage.setItem('accountsCatch', accounts[0])
  account = accounts[0]

  // 获取web3 对象
  const web3js = new Web3(ethereum)

  // 获取链ID
  const networkId = await web3js.eth.net.getId();
  
  if (chainNetwork[0].chainId !== parseInt(networkId)) {
    // 切换网络
    const data = [{
      chainId: Web3.utils.numberToHex(chainNetwork[0].chainId),
      chainName: chainNetwork[0].chainName,
      nativeCurrency: {
        name: chainNetwork[0].name,
        symbol: chainNetwork[0].symbol,
        decimals: chainNetwork[0].decimals,
      },
      rpcUrls: chainNetwork[0].rpcUrls,
      blockExplorerUrls: chainNetwork[0].blockExplorerUrls,
    }];
    return await ethereum
        .request({method: "wallet_addEthereumChain", params: data});
  }

  resolve({
    injectedWeb3: type,
    web3: web3js,
    networkId,
    account
  })
})

export default getWeb3