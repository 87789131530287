export const chainNetwork = [
  // 主网
{
  chainId: 1,
    chainName: 'ETH Mainnet',
    name: 'ETH',
    symbol: 'ETH',
    decimals: 18,
    rpcUrls: ['https://rpc.ankr.com/eth'],
    blockExplorerUrls: ['https://etherscan.io/'],
    aliasName: 'ETH',
    nickName: 'ETH',
    mintContract: '0x612623500d6772394f779aF1FB73905845b78F7f'
},
//测试网
{
  chainId: 97,
  chainName: 'BSC Mainnet',
  name: 'BNB',
  symbol: 'BNB',
  decimals: 18,
  rpcUrls: ['https://bsc-testnet.publicnode.com'],
  blockExplorerUrls: ['https://bscscan.com/'],
  aliasName: 'BNB',
  nickName: 'BNB',
  mintContract: '0xa04d318CD951b623F3c11e049bF17D3CB193832b'
}
]