import getWeb3 from '@/utils/getWeb3'
import Web3 from 'web3'
import Mint from '@/json/Mint.json'
import { chainNetwork } from '@/contants'

const state = {
    web3: {
      isInjected: null, // 钱包类型 1 OKX，2MetaMask
      //web3Instance: false, // web3对象
      networkId: null, // 链网络
      account: null, // 用户钱包地址
      error: null, // 错误信息
      initial: false // web3 是否初始化完成
    },
    user:{
        balance: null, // 钱包余额
        claimPrice: null, // 手续费
        totalClaimable: 210000, // 剩下数量
    }
}

const mutations = {
    registerWeb3Instance(state, payload) {
        const result = payload
        const web3Copy = state.web3
        web3Copy.account = result.account
        web3Copy.networkId = result.networkId
        web3Copy.isInjected = result.injectedWeb3
        web3Copy.web3Instance = result.web3
        web3Copy.initial = true
        state.web3 = web3Copy
    },
    disconnected(state, payload) {
        const web3Copy = state.web3;
        web3Copy.account = null;
        web3Copy.networkId = null;
        web3Copy.isInjected = null;
        web3Copy.web3Instance = null;
        web3Copy.initial = false;
        state.web3 = web3Copy
    },
    accountsChanged(state, payload) {
        state.web3.account = payload.account
    },
    chainChanged(state, payload) {
        state.web3.networkId = payload.networkId
    },
    updateUser(state, payload){
        const result = payload;
        const userCopy = state.user;
        userCopy.balance = result.balance;
        userCopy.claimPrice = result.claimPrice;
        userCopy.totalClaimable = result.totalClaimable;
        state.user = userCopy;
    }
}

const actions = {
    async getData({ commit,state }) {
        const web3 = new Web3(new Web3.providers.HttpProvider(chainNetwork[0].rpcUrls[0]));
        const contractInstance = new web3.eth.Contract(Mint.abi, chainNetwork[0].mintContract);
        const totalClaimable = await contractInstance.methods.totalClaimable().call().catch(() => {return '0'});
        const claimPrice = await contractInstance.methods.claimPrice().call().catch(() => {return '0'});
        let balance = 0;
        if(state.web3.initial){
            balance = await contractInstance.methods.userClaims(state.web3.account).call().catch(() => {return '0'});
            balance = balance * 1000;
        }
        commit('updateUser', {totalClaimable,claimPrice,balance})
    },
    registerWeb3({ commit }) {
        getWeb3().then(result => {
          commit('registerWeb3Instance', result)
        }).catch(e => {
          
        })
    
    
    },
    // 账户切换
    accountsChanged({ commit }, payload) {
        commit('accountsChanged', payload)
    },
    // 链切换
    chainChanged({ commit }, payload) {
        commit('chainChanged', payload)
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
  }
  